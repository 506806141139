.pricing-body {
    background: white;
    background-color: white;
}

.PriceButtons {
    padding-top: 40px;
    margin-right: 1px;
}

.PriceItems {
    margin-top: 30px;
}

.PriceItemsList {
    margin-top: 30px;
    margin-bottom: 30px;
}

.PriceItemsListEntry {
    padding-right: 30px;
}


.PriceText {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 24px;
    color: #999;
    max-width: 85%;
}


.PriceTextAmount {
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #999;
}
.PriceTextHidden {
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: white;
}
