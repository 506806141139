/*============================================================
[Master Stylesheet]
Template Name:     portfar
Version:        1.0.0
Author:         Themepresss
URL:            https://themeforest.net/user/themepresss
=========================================================*/
/*=======================================================
TABLE OF CONTENTS:

0.1  Template Reset Style
0.2  header-style
0.3  hero slider
0.4  hero-style-1
0.5  about-area
0.6  service-area
0.7  protfolio-section
0.8  testimonial-area
0.9  pricing-section
1.0  blog-section
1.1  contact-area
1.2  footer-area


/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&family=Pacifico&display=swap');
/*--------------------------------------------------------------
#0.1    Template Reset Style
--------------------------------------------------------------*/
html {
  font-size: 15px;
}

body {
  font-family: "Heebo", sans-serif;
  background-color: #111;
  font-size: 15px;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}

p {
  color: #999;
  line-height: 1.7em;
}

h1,
h2 {
  font-family: 'Pacifico', cursive;
  font-size: 3.2rem;
  margin-bottom: 40px;
  color: #FDB7B9;
}
h3,
h4,
h5,
h6 {
  font-family: 'Pacifico', cursive;
}

ul {
  padding-left: 0;
  margin: 0;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover,
a:focus {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.owl-carousel .owl-item img {
  width: auto;
}

button:focus {
  outline: none;
}

.pricing-body {
  background: white;
  background-color: white;
}

/*--------------------------------------------------------------
#0.2   Global Elements
--------------------------------------------------------------*/
.page-wrapper {
  position: relative;
  overflow: hidden;
}
.borderd{
  border: none;
  position: absolute;
  left: 50%;
  top: 0;
  width: 1px;
  height: 100%;
  background: rgba(102,102,102,.3)!important;
  z-index: -1;
}
.hero-slider-wrapper{
  position: relative;
}

.box-style {
  padding: 0 60px;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1400px;
  }
}
@media (max-width: 1199px) {
  .box-style {
    padding: 0 35px;
  }
}

@media (max-width: 991px) {
  .box-style {
    padding: 0 20px;
  }
}

@media (max-width: 767px) {
  .box-style {
    padding: 0 10px;
  }
}

.wow {
  visibility: hidden;
}

.fi:before {
  margin: 0;
}

.section-padding {
  padding: 100px 0;
  background: white;
}

@media (max-width: 991px) {
  .section-padding {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .section-padding {
    padding: 90px 0;
  }
}

.mr-bt-0 {
  margin-bottom: 0 !important;
}

.section-title h2 {
  font-size: 48px;
  font-size: 3.2rem;
  font-weight: bold;
  position: relative;
  text-transform: capitalize;
  margin: 0.31em 0 0;
  color: #FDB7B9;
  margin-bottom: 50px;
  margin-top: -50px;
}

.section-title span {
  color: #ddd;
  margin-bottom: 10px;
}



.template-btn,
.btn.template-btn{
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-size: 1.06667rem;
  color: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: #FDB7B9;
  padding: 0 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  border-radius: 50px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.template-btn:hover,
.btn.template-btn:hover{
  background: #C17779;
  color: #ffffff;
}
.btn.template-btn:focus{
  box-shadow: none;
}
/*** back to top **/
.back-to-top {
  background-color: rgba(102, 102, 102, 0.9);
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 2px solid #666;
  text-align: center;
  display: none;
  position: fixed;
  z-index: 999;
  right: 15px;
  bottom: 15px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 991px) {
  .back-to-top {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.back-to-top:hover {
  background-color: #333;
}

.back-to-top i {
  font-size: 18px;
  font-size: 1.125rem;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*--------------------------------------------------------------
 header
--------------------------------------------------------------*/
.site-header {
  /* navigation open and close btn hide for width screen */
  /* style for navigation less than 992px */
  /*navbar collaps less then 992px*/
  /*** cart-search-contact ***/
}

@media(max-width:1200px){
  .header-style-1{
    display: block!important;
  }
}

.site-header .navigation {
  background-color: transparent;
  margin-bottom: 0;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.site-header .navigation > .container {
  position: relative;
}
.navbar-brand{
  margin-bottom: 30px;
}
.site-header .navigation .navbar-brand img{
  max-width: 80px;
}

.site-header #navbar {
  font-family: 'Berkshire Swash', cursive;
  text-transform: uppercase;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  /*** mega-menu style ***/
}

.site-header #navbar > ul li a:focus {
  text-decoration: none;
}

@media screen and (min-width: 1200px) {
  .site-header #navbar {
    /*** hover effect ***/
  }
  .site-header #navbar li {
    position: relative;
  }
  .site-header #navbar > ul > li > a {
    font-size: 16px;
    font-size: 1.06667rem;
  }
  .site-header #navbar > ul .sub-menu {
    background-color: #fff;
    width: 250px;
    border-top: 2px solid #ddd;
    position: absolute;
    left: 0;
    top: 130%;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  .site-header #navbar > ul > li .sub-menu li {
    border-bottom: 1px solid #ececec;
  }
  .site-header #navbar > ul > li .sub-menu a {
    font-size: 15px;
    font-size: 1rem;
    display: block;
    padding: 12px 20px;
  }
  .site-header #navbar > ul > li > .sub-menu .sub-menu {
    left: 110%;
    top: 0;
  }
  .site-header #navbar > ul > li > .sub-menu > .menu-item-has-children > a {
    position: relative;
  }
  .site-header #navbar > ul > li > .sub-menu > .menu-item-has-children > a:before {
    font-family: "themify";
    content: "\e64b";
    font-size: 11px;
    font-size: 0.73333rem;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .site-header #navbar > ul > li:hover > .sub-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
  .site-header #navbar .sub-menu > li:hover > .sub-menu {
    left: 100%;
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .site-header #navbar > ul > li a {
    display: inline-block;
    font-size: 14px;
    font-size: 0.93333rem;
  }
  .site-header #navbar > ul > li .sub-menu li {
    border-bottom: 1px solid #e6e6e6;
  }
  .site-header #navbar > ul .sub-menu > li:last-child {
    border-bottom: 0;
  }
  .site-header #navbar > ul > li > .sub-menu a {
    padding: 8px 15px 8px 45px;
  }
  .site-header #navbar > ul > li > .sub-menu .sub-menu a {
    padding: 8px 15px 8px 65px;
  }
  .site-header #navbar > ul .menu-item-has-children > a {
    position: relative;
  }
  .site-header #navbar > ul .menu-item-has-children > a:before {
    font-family: "themify";
    content: "\e64b";
    font-size: 11px;
    font-size: 0.73333rem;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 992px) {
  .site-header #navbar {
    /*** hover effect ***/
  }
  .site-header #navbar .has-mega-menu {
    position: static;
  }
  .site-header #navbar .mega-menu,
  .site-header #navbar .half-mega-menu {
    background-color: #fff;
    padding: 20px;
    border-top: 2px solid #ddd;
    position: absolute;
    right: 0;
    top: 120%;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  .site-header #navbar .mega-menu {
    width: 1140px;
    right: 15px;
  }
  .site-header #navbar .half-mega-menu {
    width: 585px;
  }
  .site-header #navbar .mega-menu-box-title {
    font-size: 14px;
    font-size: 0.93333rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    padding-bottom: 7px;
    margin-bottom: 7px;
    border-bottom: 1px solid #e6e6e6;
  }
  .site-header #navbar .mega-menu-list-holder li a {
    font-size: 14px;
    font-size: 0.93333rem;
    display: block;
    padding: 7px 8px;
    margin-left: -8px;
  }
  .site-header #navbar .has-mega-menu:hover > ul {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 1199px) {
  .site-header #navbar > ul .mega-menu {
    width: 950px;
    right: 15px;
  }
  .site-header #navbar > ul .half-mega-menu {
    width: 485px;
  }
}

@media (max-width: 991px) {
  .site-header #navbar > ul .mega-menu,
  .site-header #navbar > ul .half-mega-menu {
    width: auto;
  }
  .site-header #navbar > ul .mega-menu .row,
  .site-header #navbar > ul .half-mega-menu .row {
    margin: 0;
  }
  .site-header #navbar .mega-menu-content > .row > .col {
    margin-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .site-header #navbar .mega-menu .mega-menu-list-holder a {
    padding: 5px 15px 5px 40px;
  }
  .site-header #navbar .mega-menu .mega-menu-box-title {
    font-size: 14px;
    font-size: 0.93333rem;
    text-transform: uppercase;
    display: block;
    border-bottom: 1px dotted #b3b3b3;
    padding: 0 0 4px 5px;
    margin: 0 25px 8px 25px;
  }
}

@media screen and (min-width: 1200px) {
  .site-header .navbar-header .open-btn {
    display: none;
  }
  .site-header #navbar .close-navbar {
    display: none;
  }
}

@media (max-width: 1200px) {
  .site-header {
    /* class for show hide navigation */
  }
  .site-header .container {
    width: 100%;
  }
  .site-header .navbar-header button {
    background-color: #FDB7B9;
    width: 40px;
    height: 35px;
    border: 0;
    padding: 5px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    outline: 0;
    position: absolute;
    right: 15px;
    top: 30px;
    z-index: 20;
  }
  .site-header .navbar-header button span {
    background-color: #fff;
    display: block;
    height: 2px;
    margin-bottom: 5px;
  }
  .site-header .navbar-header button span:last-child {
    margin: 0;
  }
  .site-header #navbar {
    background: #191919;
    display: block !important;
    width: 280px;
    height: 100% !important;
    margin: 0;
    position: fixed;
    left: -300px;
    top: 0;
    z-index: 100;
  }
  .navigation-holder .navbar-header{
    padding: 20px 0;
  }
  .site-header #navbar.navigation-holder.active{
    left: 0px;
    background-color: #FDB7B9;
  }
  .site-header #navbar ul a {
    color: #fff;
    font-family: "Heebo", sans-serif;
  }
  .site-header #navbar ul a:hover,
  .site-header #navbar ul li.current a {
    color: #ddd;
  }
  .site-header #navbar .navbar-nav {
    height: 100%;
  }
  .site-header #navbar .close-navbar {
    background-color: #FDB7B9;
    width: 40px;
    height: 40px;
    color: #fff;
    border: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    outline: none;
    position: absolute;
    right: -18px;
    top: 10px;
    z-index: 112;
  }

  .site-header #navbar > ul > li > a {
    padding: 10px 15px 10px 35px;
  }
  .site-header .slideInn {
    right: 0 !important;
  }
}

@media (max-width: 767px) {
  .site-header .navbar-header .navbar-brand {
    font-size: 24px;
  }
  .site-header #navbar .navbar-nav {
    margin: 0;
  }
}

@media (max-width: 991px) {
  .site-header .navbar-collapse.collapse {
    display: none;
  }
  .site-header .navbar-collapse.collapse.in {
    display: block;
  }
  .site-header .navbar-header .collapse,
  .site-header .navbar-toggle {
    display: block;
  }
  .site-header .navbar-header {
    float: none;
  }
  .site-header .navbar-header a{
    margin-bottom: 10px;
  }
  .site-header .navbar-right {
    float: none;
  }
  .site-header .navbar-nav {
    float: none;
  }
  .site-header .navbar-nav > li {
    float: none;
  }

  .site-header #navbar .navbar-nav {
    padding-left: 40px;
  }
}


/*******************************************
0.2	header-style 1
*******************************************/
.header-style-1 {
  /*** .cart-search-contact ***/
}
.header-style-1 #navbar > ul li a{
  position: relative;
}

.header-style-1 #navbar > ul li a:before{
  position: absolute;
  right: -50px;
  top: 50%;
  width: 20px;
  height: 2px;
  background: #fff;
  content: "";
  transition: all .3s;
  opacity: 0;
  visibility: hidden;
}
.header-style-1 #navbar > ul li a.active:before{
  right: -30px;
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 1200px) {
  .header-style-1{
    top: -140px;
  }
}
@media screen and (min-width: 992px) {
  .header-style-1 {
    position: absolute;
    width: 100%;
    z-index: 99;
  }
  .header-style-1 ul {
    list-style: none;
  }

  .header-style-1 .navigation-holder {
    background: #FDB7B9;
    width: 350px;
    height: 100% !important;
    padding: 25px 60px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    padding-top: 0;
  }

  @media(max-width:1500px){
    .header-style-1 .navigation-holder {
      width: 300px;
    }
  }
  @media(max-width:1200px){
    .header-style-1 .navigation-holder {
      left: -320px;
    }
  }
  .header-style-1 .navigation-holder > ul {
    float: none;
    height: 100%;
  }
  .header-style-1 #navbar > ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .header-style-1 #navbar > ul > li {
    float: none;
  }
  .header-style-1 #navbar > ul li a {
    text-transform: capitalize;
    display: block;
    position: relative;
    font-family: "Heebo", sans-serif;
    display: inline-block;
  }
  .header-style-1 #navbar > ul li a:before{
    position: absolute;
    right: -50px;
    top: 50%;
    width: 20px;
    height: 2px;
    background: #fff;
    content: "";
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
  }
  .header-style-1 #navbar > ul li a.active:before{
    right: -30px;
    opacity: 1;
    visibility: visible;
  }
  .header-style-1 #navbar > ul li a,
  .header-style-1 #navbar > ul > li .sub-menu a {
    font-size: 16px;
    font-size: 1.06667rem;
    color: #266524;
    padding: 15px;
  }
  .header-style-1 #navbar > ul > li .sub-menu a {
    text-transform: none;
    font-weight: 300;
  }
  .header-style-1 #navbar > ul > li .sub-menu a:hover {
    color: #ddd;
  }
  .header-style-1 #navbar > ul li a {
    padding: 20px 15px;
  }
  .header-style-1 #navbar > ul .sub-menu {
    background-color: transparent;
    width: auto;
    height: 0;
    border-top: 0;
    position: static;
    left: 0;
    top: 0;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    padding-left: 20px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  .header-style-1 #navbar > ul .sub-menu.open-submenu {
    height: auto !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .header-style-1 #navbar > ul > li .sub-menu li {
    border-bottom: 0;
  }
  .header-style-1 #navbar > ul > li:hover > .sub-menu {
    top: auto;
    visibility: hidden;
    opacity: 0;
  }
  .header-style-1 #navbar > ul .sub-menu > li:hover > .sub-menu {
    left: auto;
    visibility: hidden;
    opacity: 0;
  }
  .header-style-1 .close-navbar-2 {
    background-color: #FDB7B9;
    width: 40px;
    height: 40px;
    color: #fff;
    border: 0;
    border-radius: 50%;
    outline: none;
    position: absolute;
    right: -18px;
    top: 10px;
    z-index: 999;
    display: none;
  }
  @media(max-width:991px){
    .header-style-1 .close-navbar-2{
      display: block;
      background-color: #FDB7B9;
    }
  }
  .header-style-1 #navbar > ul .menu-item-has-children > a:before {
    font-family: "themify";
    content: "\e64b";
    font-size: 11px;
    font-size: 0.6875rem;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #FDB7B9;
  }
}

@media (max-width: 991px) {
  .header-style-1 .close-navbar-2 {
    display: none;
  }
}

.header-style-1 .navigation {
  padding: 12px 0;
}

@media (max-width: 767px) {
  .header-style-1 .navigation {
    padding: 0;
  }
}

.header-style-1 .menu-info {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .header-style-1 .menu-info {
    display: none;
  }
}

.header-style-1 .menu-info ul {
  overflow: hidden;
  list-style: none;
}

.header-style-1 .menu-info ul li {
  font-size: 18px;
  font-size: 1.2rem;
  padding-bottom: 2px;
  float: left;
  line-height: 1em;
}

@media (max-width: 991px) {
  .header-style-1 .menu-info ul li {
    font-size: 16px;
    font-size: 1.06667rem;
    padding-bottom: 2px;
  }
}

.header-style-1 .menu-info ul > li + li {
  margin-left: 35px;
}

@media (max-width: 991px) {
  .header-style-1 .menu-info ul > li + li {
    margin-left: 25px;
  }
}

.header-style-1 .menu-info ul .fi {
  position: relative;
  top: 3px;
  display: inline-block;
  padding-right: 3px;
}

@media (max-width: 991px) {
  .header-style-1 .menu-info ul .fi {
    top: 2px;
  }
}

.header-style-1 .menu-info ul .fi:before {
  font-size: 22px;
  font-size: 1.46667rem;
  color: #ddd;
}

@media (max-width: 991px) {
  .header-style-1 .menu-info ul .fi:before {
    font-size: 20px;
    font-size: 1.33333rem;
  }
}

.header-style-1 .menu-open-btn-holder {
  background-color: #191919;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 15px;
  top: 22px;
}

@media (max-width:1200px) {
  .header-style-1 .menu-open-btn-holder {
    display: none;
  }
}

.header-style-1 .menu-open-btn-holder button {
  background: transparent;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
}

.header-style-1 .menu-open-btn-holder button span {
  background-color: #fff;
  width: 27px;
  height: 2px;
  position: absolute;
  top: 12px;
  left: 9px;
}

.header-style-1 .menu-open-btn-holder button > span:nth-child(2) {
  top: 22px;
}

.header-style-1 .menu-open-btn-holder button > span:nth-child(3) {
  width: 18px;
  top: 32px;
}

@media (max-width: 991px) {
  .header-style-1 .navbar-header button {
    top: 25px;
  }
}

.header-style-1 .navigation-holder.open-navigation-menu {
  right: 0;
}



.nav>li>a {
  padding: 0;
}
.header-style-1 #navbar > ul li a {
  padding: 0;
  margin-bottom: 20px;
  cursor: pointer;
}
.header-style-2{
  position: relative;
}

.header-style-1 #navbar > ul li:last-child a {
  margin-bottom: 0;
}
.header-style-1 .navigation-holder {
  left: 0;
}

.header-style-1 .navigation-holder.active {
  left: 0px;
}
#scrool {
  padding-left: 350px;
}

@media(max-width:1500px){
  #scrool {
    padding-left: 300px;
  }
}

@media(max-width:1200px){
  #scrool {
    padding-left: 0px;
  }
}
/*--------------------------------------------------------------
0.3	hero slider
--------------------------------------------------------------*/
.hero {
  position: relative;
  height: 100vh;
  z-index: 999;
  overflow: hidden;
}

@media (max-width: 767px) {
  .hero {
    min-height: 420px;
  }
}

.hero .slide {
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media (max-width: 767px) {
  .hero .slide {
    min-height: 420px;
  }
}

.hero .slide .slider-bg {
  display: none;
}

.hero .slide:focus {
  outline: none;
}



.hero .slide .container {
  height: 100%;
  display: table;
  position: relative;
  z-index: 2;
  margin: 20px;
}

@media (max-width: 767px) {
  .hero .slide .container {
    padding: 0;
  }
}

.hero .slide .row {
  display: table-cell;
  vertical-align: middle;
}

.hero .slide .slider-bg {
  display: none;
}

.hero .slide:focus {
  outline: none;
}


.hero .slide .container {
  height: 100%;
  display: table;
  position: relative;
  z-index: 2;
}

@media (max-width: 767px) {
  .hero .slide .container {
    padding: 0;
  }
}

.hero .slide .row {
  display: table-cell;
  vertical-align: middle;
}



@media (max-width: 991px) {
  .hero .pagi-info {
    font-size: 20px;
    font-size: 1.33333rem;
  }
}

@media (max-width: 767px) {
  .hero .pagi-info {
    display: none;
  }
}

.hero .social-links {
  width: auto;
  position: absolute;
  right: 6px;
  top: 50%;
  z-index: 1;
}
.hero .social-links .overlay{
  position: absolute;
  left: -10px;
  top: -179px;
  background: #191919;
  width: 90px;
  height: 265%;
  z-index: -1;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}


@media (max-width: 1300px) {
  .hero .social-links {
    right: 25px;
  }
}

@media (max-width: 850px) {
  .hero .social-links {
    display: none;
  }
}

.hero .social-links:before {
  content: "";
  width: 1px;
  height: 50px;
  background: #fff;
  position: absolute;
  left: 5px;
  top: -70px;
}

.hero .social-links:after {
  color: #fff;
  content: "SOCIAL";
  position: absolute;
  left: -35px;
  top: -135px;
  letter-spacing: 5px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hero .social-links ul {
  list-style: none;
}

.hero .social-links ul > li + li {
  margin-top: 10px;
}

.hero .social-links li a {
  font-size: 14px;
  font-size: 0.93333rem;
  color: #fff;
}

.hero .social-links li a:hover {
  color: #ddd;
}

.hero .slide-caption > div {
  overflow: hidden;
}

.hero .slide-caption {
  padding-top: 0px;
  padding-bottom: 75px;
}
@media(max-width:1500px){
  .hero .slide-caption {
    padding-top: 0px;
    padding-bottom: 50px;
  }
}
@media(max-width:1500px){
  .hero .slide-caption {
    padding-top: 40px;
    padding-bottom: 0px;
  }
}
.hero .slide-caption > div * {
  -webkit-animation: fadeInLeft 1.5s both;
  animation: fadeInLeft 1.5s both;
}

.hero .slide-caption > div.slide-title * {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.hero .slide-caption > div.slide-subtitle * {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.hero .slide-caption > div.btns * {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.hero .hero-slider .slick-current .slide-caption > div * {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

.hero .hero-slider .slick-current .slide-caption > div.slide-title * {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.hero .hero-slider .slick-current .slide-caption > div.slide-subtitle * {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.hero .hero-slider .slick-current .slide-caption > div.btns * {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.slider-image img{
  height: 100%;
}
.br-app{
  position: relative;
  background: #F7E3DB;
  z-index: 999;
}
.br-app:before {
  content: "";
  position: absolute;
  left: 20%;
  top: 0;
  width: 1px;
  height: 100%;/*
  background: rgba(102,102,102,.3);*/
  z-index: 9999;
}
.br-app:after {
  content: "";
  position: absolute;
  right: 2%;
  top: 0;
  z-index: 9999;
  width: 1px;
  height: 100%;/*
  background: rgba(102,102,102,.3);*/
}
/*==================================================
0.4  hero-style-1
====================================================*/

.hero-style-1{
  height: 100vh;
  min-height: 850px;
  position: relative;
}
.white_svg{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.white_svg svg {
  fill: #ffffff;
}

.hero-style-1 .slide {
  height:100vh;
  min-height: 850px;
}

.hero-style-1 .slide-subtitle h4{
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 8px;
  color: #040018;
  margin-top: 50;
  position: relative;
  font-family: "Heebo", sans-serif;
}

@media (max-width: 991px) {
  .hero-style-1 .slide-subtitle h4 {
    font-size: 16px;
    font-size: 1.06667rem;
    margin-top: 50;
  }
}

@media (max-width: 767px) {
  .hero-style-1 .slide-subtitle h4{
    font-size: 14px;
    font-size: 0.93333rem;
    letter-spacing: 4px;
  }
}
@media (max-width: 500px) {
  .hero-style-1 .slide-subtitle h4{
    font-size: 14px;
    font-size: 0.93333rem;
    letter-spacing: 4px;
    align-content: flex-end;
  }
}


.hero-style-1 .slide-title h2 {
  font-size: 60px;
  line-height: 1.2em;
  text-transform: capitalize;
  color: #FDB7B9;
  margin-bottom: 30px;
  margin-top: 20px;
  text-shadow: #040018 1px 0 0px, #040018 0 1px 0px, #040018 -1px 0 0px, #040018 0 -1px 0px;
}

.hero-style-1 .slide-subtitle {
  margin-bottom: 0;
}
.hero-style-1 .slide-subtitle h4{
  position: relative;
  margin-bottom: 0;
  display: inline-block;
}


.hero-style-1 .slide-caption:hover h4:before{
  right: -80px;
}
@media (max-width: 1400px) {
  .hero-style-1 .slide-title h2{
    font-size: 90px;
  }
}
@media (max-width: 1199px) {
  .hero-style-1 .slide-title h2 {
    font-size: 60px;
    font-size: 4rem;
  }
}

@media (max-width: 991px) {
  .hero-style-1 .slide-title h2{
    font-size: 50px;
    font-size: 3.33333rem;
  }
}

@media (max-width: 767px) {
  .hero-style-1 .slide-title h2 {
    font-size: 20px;
    font-size: 2.66667rem;
  }
}


@-webkit-keyframes kenburn {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }
}

@keyframes kenburn {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  to {
    -webkit-transform: scale3d(1.3, 1.3, 1.3);
    transform: scale3d(1.3, 1.3, 1.3);
  }
}
.sticky-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: -400px;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -o-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.sticky-on {
  opacity: 1;
  top: 0;
  visibility: visible;
}

.header-style-1 .sticky-header {
  -webkit-box-shadow: 0 2px 15px rgba(125, 125, 125, 0.1);
  box-shadow: 0 2px 15px rgba(125, 125, 125, 0.1);
}

.header-style-1 .sticky-header {
  background-color: #333;
}
.copy p{
  color:#ddd;
}


/*==============================
0.5 about-area
===============================*/
.white_svg.svg_white svg {
  fill:  #F7E3DB;
}
.tp-about-section {
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 200px;
  position: relative;
  background: #ffffff;
  z-index: 1;
}
.MuiDialog-paperScrollPaper {
  max-width: 1100px!important;
  width: 1100px!important;
  background-color: #ffffff !important;
}
.tp-about-section:before{
  content: "";
  position: absolute;
  left: 0;
  top:44%;
  width: 100%;
  height: 1px;/*
  background:rgba(102,102,102,.3);*/
  z-index: -1;
}

.tp-about-section .tp-about-wrap .tp-about-img {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 505px;
  margin: 0 auto;
}
.tp-about-section .tp-about-wrap .tp-about-img img {
  width: 100%;
}
.tp-about-section .tp-about-wrap .tp-about-img .tp-ab-shape-1 {
  position: absolute;
  left: -60px;
  top: -60px;
  z-index: -1;
}
.tp-about-section .tp-about-wrap .tp-about-img .tp-ab-shape-2 {
  position: absolute;
  right: -35px;
  bottom: -35px;
  z-index: 11;
}
.tp-about-section .tp-about-text {
  max-width: 595px;
}
.tp-about-section .tp-about-text .tp-about-icon {
  width: 50px;
  height: 50px;
  background: #f3f6fe;
  line-height: 50px;
  text-align: center;
  border-radius: 6px;
}
.tp-about-section .tp-about-text .tp-about-icon .fi:before{
  font-size: 30px;
}
.tp-about-section .tp-about-text .tp-about-icon img {
  width: 30px;
  line-height: 30px;
  height: 30px;
}
.tp-about-section .tp-about-text h2 {
  font-size: 50px;
  margin: 30px 0;
  color: #FDB7B9;
}

@media(max-width:1500px){
  .tp-about-section .tp-about-text h2 {
    font-size:40px;
  }

}

@media(max-width:1400px){
  .tp-about-section .tp-about-text h2 {
    font-size:35px;
    margin: 20px 0;
  }

}
@media(max-width:991px){
  .tp-about-section .tp-about-wrap .tp-about-img{
    margin: 0;
    margin-bottom: 40px;
  }
  .tp-about-section {
    padding-top: 80px;
    padding-bottom: 120px;
  }
}

.tp-about-section .tp-about-text .signeture {
  padding: 15px 0;
}
.tp-about-section .tp-about-text .signeture p {
  font-size: 17px;
  color: #888;
  margin-top: 10px;
}

/* skill-area */

.skill-area {
  background: #2a2a2a;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  overflow: unset;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #2a2a2a;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #FDB7B9;
  font-size: 24px;
  color: #fff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}

.progress .progress-value span{
  display: block;
  font-size: 18px;
}

.progress.blue .progress-bar {
  border-color: #377dff;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
  border-color: #fdba04;
}

.progress.yellow .progress-left .progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}

.progress.pink .progress-bar {
  border-color: #fe3e57;
}

.progress.pink .progress-left .progress-bar {
  animation: loading-4 0.4s linear forwards 1.8s;
}

.progress.green .progress-bar {
  border-color: #00c9a7;
}

.progress.green .progress-left .progress-bar {
  animation: loading-5 1.2s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(46deg);
    transform: rotate(46deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}

@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: 60px;
  }
}

.progress:before {
  position: absolute;
  left: -14px;
  top: -15px;
  width: 120%;
  height: 120%;
  background: #202020;
  content: "";
  border-radius: 50%;
}


/*skill-area-end*/


.tp-protfolio-area-4 {
  background: #f9f9f9;
}

.tp-contact-area4 {
  background: #f9f9f9;
  padding-bottom: 70px;
}

@media(max-width: 1200px) {

  .tp-about-area-3 .tp-about-text,
  .tp-about-area-5 .tp-about-text {
    padding-top: 35px;
    background-color: #FDB7B9;


  }
}


/*0.6 service-area start*/

.service-area {
  background: #F7E3DB;
  padding-bottom: 160px;
  position: relative;
}
.services-icon {
  background: #ffffff;
}
.service-section .services-wrapper {
  text-align: center;
  margin-bottom: 30px;
  padding: 40px 20px 30px;
  background: #FDB7B9;
}


.services-icon-wrapper {
  position: relative;
  z-index: 1;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 50px;
  background: transparent;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ddd;
  margin: auto;
}
.service-section .services-icon-wrapper .service-dot {
  width: 100%;
  height: 100%;
  left: 56%;
  top: 8%;
}
.service-section .services-icon-wrapper .service-dot:nth-child(1) {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-animation: service-dot_1 2s infinite linear;
  -moz-animation: service-dot_1 2s infinite linear;
  -o-animation: service-dot_1 2s infinite linear;
  animation: service-dot_1 2s infinite linear;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}
.services-icon-wrapper i {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.service-section .services-wrapper:hover .service-dot:nth-child(1) {
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running; }


@-webkit-keyframes service-dot_1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes service-dot_1 {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes service-dot_1 {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes service-dot_1 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes service-dot_2 {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg); } }

@-moz-keyframes service-dot_2 {
  0% {
    -moz-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -moz-transform: rotate(540deg);
    transform: rotate(540deg); } }

@-o-keyframes service-dot_2 {
  0% {
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -o-transform: rotate(540deg);
    transform: rotate(540deg); } }

@keyframes service-dot_2 {
  0% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg); } }
.service-section .services-icon {
  color: #ffc600;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: -webkit-linear-gradient(0deg, #ffc600 0%, #ff4200 100%);
  background-image: -moz-linear-gradient(0deg, #ffc600 0%, #ff4200 100%);
}

.services-icon-wrapper .fi:before {
  font-size: 40px;
  color: #ffffff;
}
.dots,
.dots2 {
  width: 15px;
  height: 15px;
  background: #666;
  border-radius: 50%;
  left: 15px;
  top: 0;
  position: relative;
}
.dots2{
  right: -70px;
  left: auto;
  top: auto;
  bottom: -70px;
}
.service-content h2{
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
  margin-top: 30px;
}

.service-content .btn{
  color: rgba(2255, 255, 2255, .6);
  transition: all .3s;
}
.service-content .btn:focus{
  box-shadow: none;
}

.service-content .btn:hover{
  color: rgba(2255, 255, 2255);
}

.gallery-section {
  padding-bottom: 100px;
  border-top: 1px solid rgba(102,102,102,.3);
}

@media (max-width: 991px) {
  .service-section .services-wrapper {
    margin-bottom: 30px;
    padding: 35px 16px 30px;
  }
}

/*--------------------------------------------------------------
0.7 protfolio-section
--------------------------------------------------------------*/
.gallery-section {
  padding-bottom: 70px;
  position: relative;
  background: #FDB7B9;
  padding-bottom: 160px;
}

@media (max-width: 991px) {
  .gallery-section {
    padding-bottom: 83px;
  }
}

@media (max-width: 767px) {
  .gallery-section {
    padding-bottom: 73px;
  }
}

.gallery-section .gallery-filters {
  text-align: center;
  margin-bottom: 35px;
}

@media (max-width: 767px) {
  .gallery-section .gallery-filters {
    margin-bottom: 30px;
  }
}

.gallery-section .gallery-filters ul {
  display: inline-block;
  overflow: hidden;
  list-style: none;
}

.gallery-section .gallery-filters ul li {
  display: inline-block;
}

.gallery-section .gallery-filters ul > li + li {
  margin-left: 15px;
}

.gallery-section .gallery-filters ul li a {
  font-weight: 500;
  color: #ddd;
  display: block;
  text-transform: capitalize;
}
.gallery-section .gallery-filters ul .current {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 767px) {
  .gallery-section .gallery-filters ul li a {
    font-size: 15px;
    font-size: 1rem;
  }
}

@media (max-width: 500px) {

}


@media (max-width: 991px) {

}

@media (max-width: 767px) {

}

@media (max-width: 550px) {

}
.gallery-section .grid{
  position: relative;
}
.gallery-section .grid img{
  width: 100%;
}
.gallery-section .grid:before {
  position: absolute;
  content: "";
  left: 30px;
  right: 30px;
  top: 15px;
  bottom: 15px;
  background: rgba(255,255,255,.5);
  transition: all .3s;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
}
.gallery-section .grid:hover:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.gallery-section .item{
  position: relative;
  margin-bottom: 30px;
  transition: all .3s;
  overflow: hidden;
}
.icon {
  position: absolute;
  top:43%;
  left: 0;
  bottom: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;
  transform: scale(0);
  transform: all .9s;
}
.gallery-section .grid:hover .icon{
  transform: scale(1);
}
.icon i{
  font-size: 30px;
  color:#000;
  font-weight: 700;
}
.gallery-section .section-title {
  margin-bottom: 30px;
}

/*=======================================
0.8 testimonial-area start
=========================================*/
.testimonial-area{
  padding: 100px 0 210px;
  position: relative;
}
.testimonial-img {
  width: 11%;
  margin: auto;
  z-index: 99;
  position: relative;
  border-radius: 50%;
  margin-bottom: 20px;
}
.testimonial-img img{
  border-radius: 50%;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.3);
}
.testimonial-active{
  max-width: 800px;
  position: relative;
}
.testimonial-item {
  text-align: center;
  position: relative;
  background: #ffffff;
  padding: 40px;
}

.testimonial-item p {
  padding-top: 10px;
  position: relative;
  font-size: 18px;
  color:#999;
}

.testimonial-item h4{
  font-weight: 700;
  font-size: 24px;
  color:#999;
  padding-top: 10px;
  font-family: 'Open Sans', sans-serif;
}
.testimonial-item span{
  font-size: 14px;
  color:#ddd;
}
.testimonial-active {
  margin: auto;
}
.testimonial-active .slick-dots {
  position: absolute;
  bottom: -50px;

}

.slick-dots {
  position: absolute;
  bottom: -50px;

}

.testimonial-active .slick-dots li button:before {
  font-family: 'slick';
  font-size: 10px;
}
.testimonial-active .slick-dots li button:after{
  position: absolute;
  left: 0;
  top: -10%;
  width: 102%;
  height: 102%;
  content: "";
  border: 1px solid #333;
  border-radius: 50%;
  text-align: center;
}
.testimonial-icon .fi{
  color:#ddd;
}
.testimonial-icon .fi:before{
  font-size: 40px;
}
.owl-theme .owl-dots .owl-dot span{
  display: none;
}

.slick-slide div:focus{
  outline: none;
}


.testimonial-active .slick-prev,
.testimonial-active .slick-next {
  background-color: #FDB7B9;
  width: 55px;
  height: 55px;
  z-index: 10;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.testimonial-active .slick-prev:hover,
.testimonial-active .slick-next:hover {
  background-color: #C17779;
}


@media (max-width: 991px) {

  .testimonial-active .slick-prev,
  .testimonial-active .slick-next {
    display: none !important;
  }

  .testimonial-area {
    padding: 100px 0 125px;
  }
}

.testimonial-active .slick-prev {
  left: -70px;
}

.testimonial-active .slick-prev:before {
  font-family: "FontAwesome";
  content: "\f177";
  opacity: 1;
}

.testimonial-active .slick-next {
  right: -70px;
}

@media (max-width: 1400px) {
  .testimonial-active .slick-prev {
    left: -40px;
  }
  .testimonial-active .slick-next {
    right: -40px;
  }
}


.testimonial-active .slick-next:before {
  font-family: "FontAwesome";
  content: "\f178";
  opacity: 1;
}

.testimonial-active .slick-dots {
  bottom: -30px;
}

@media screen and (min-width: 992px) {
  .testimonial-active .slick-dots {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .testimonial-item {
    padding: 20px 10px;
  }
}
/*====================================
0.9 pricing-section start
======================================*/
.pricing-section {
  padding-bottom: 160px;
  border-top: 1px solid rgba(102,102,102,.3);
  position: relative;
  background: #FDB7B9;
}

@media (max-width: 991px) {
  .pricing-section {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .pricing-section {
    padding-bottom: 50px;
  }
}

.pricing-section .pricing-grids {
  margin: 0 -15px;
}

.pricing-section .pricing-grids .grid {
  width: 33.33%;
  float: left;
  padding: 0 15px 30px;
  text-align: center;
  padding-top: 65px;
}

@media (max-width: 991px) {
  .pricing-section .pricing-grids .grid {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .pricing-section .pricing-grids .grid {
    width: 100%;
    float: none;
  }
}

.pricing-section .pricing-header {
  background-color: #000;
  padding: 0px 25px 40px;
  border-top-right-radius: 80px;
  border-top-left-radius: 80px;
}

@media (max-width: 991px) {
  .pricing-section .pricing-header {
    padding: 45px 15px 30px;
  }
}

.pricing-section .pricing-header .circuler-price {
  background-color: #202020;
  width: 130px;
  height: 130px;
  line-height: 120px;
  border-radius: 50%;
  margin: 0 auto 20px;
  border: 5px solid rgba(102, 102, 102, 0.6);
  position: relative;
  top: -65px;
  margin-bottom: -40px;
}

@media (max-width: 991px) {
  .pricing-section .pricing-header .circuler-price {
    width: 110px;
    height: 110px;
    line-height: 100px;
  }
}

.pricing-section .pricing-header .fi:before{
  font-size: 50px;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 991px) {
  .pricing-section .pricing-header h2 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.pricing-section .pricing-header h2 sup {
  font-size: 20px;
  font-size: 1.25rem;
}

.pricing-section .pricing-body h2 span {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (max-width: 991px) {
  .pricing-section .pricing-header h2 sup {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.pricing-section .pricing-header p {
  font-weight: normal;
  color: #fff;
  margin: 0;
  font-size: 30px;
}

.pricing-section .pricing-body {
  background-color: #333;
  padding: 5px 50px 30px;
  border-bottom-right-radius: 80px;
  border-bottom-left-radius: 80px;
}
@media (max-width: 1199px) {
  .pricing-section .pricing-body {
    padding: 40px 20px 50px;
  }
}

.pricing-section .pricing-body h3 {
  font-size: 28px;
  font-size: 1.75rem;
  margin: 0 0 1em;
  color:#fff;
}
.pricing-section .pricing-body h2 {
  color:#ddd;
  margin-top: 20px;
}

@media (max-width: 1199px) {
  .pricing-section .pricing-body h3 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media (max-width: 991px) {
  .pricing-section .pricing-header .circuler-price {
    top: -95px;
    margin-bottom: -70px;
  }
}

.pricing-section .pricing-body p {
  line-height: 2em;
  margin-bottom: 2em;
  color: #999;
}
.pricing-body .template-btn{
  color:#fff;
  margin-top: 15px;
  border:1px solid rgba(102,102,102,.8);
}
.pricing-body .template-btn:hover{
  color: #333;
}
.pricing-body ul{
  list-style: none;

}
.pricing-body ul li{
  padding: 20px 0;
  list-style: none;
  color: #999;
  border-bottom: 1px solid #383737;
}
/*--------------------------------------------------------------
 1.0  blog-section
--------------------------------------------------------------*/
.blog-section {
  padding-bottom: 160px;
  border-top:1px solid rgba(102,102,102,.3);
  position: relative;
}

@media (max-width: 991px) {
  .blog-section {
    padding-bottom: 70px;
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .blog-section {
    padding-bottom: 60px;
    padding-top: 0;
  }
}

.blog-section .blog-grids {
  margin: 0 -15px;
}

@media (max-width: 767px) {
  .blog-section .blog-grids {
    margin: 0 -7.5px;
  }
}

.blog-section .blog-grids .grid {
  width: calc(33.33% - 30px);
  margin: 0 15px 30px;
  float: left;
}

@media (max-width: 1199px) {
  .blog-section .blog-grids .grid {
    width: calc(50% - 30px);
  }
}

@media (max-width: 767px) {
  .blog-section .blog-grids .grid {
    width: calc(100% - 15px);
    margin: 0 7.5px 30px;
  }
}

@media (max-width: 600px) {
  .blog-section .blog-grids .grid {
    width: calc(100% - 15px);
    float: none;
  }
}

.blog-section .grid .details {
  padding: 35px 20px;
  background:#111;
}

@media (max-width: 1199px) {
  .blog-section .grid .details {
    padding: 30px 20px;
  }
}

.blog-section .grid .details h3 {
  font-size: 25px;
  line-height: 1.4em;
  margin: 0 0 0.7em;
  font-weight: 700;
}

@media (max-width: 1400px) {
  .blog-section .grid .details h3{
    font-size: 20px;
  }
  .blog-section .grid .details {
    padding: 35px 20px;
  }
}

@media (max-width: 1199px) {
  .blog-section .grid .details h3 {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .blog-section .grid .details h3 {
    font-size: 20px;
  }
  .blog-section .entry-meta{
    margin-bottom: 0;
  }
}

.blog-section .grid .details h3 a {
  color: #fff;
}

.blog-section .details p {
  font-size: 15px;
  font-size: 0.9375rem;
}

.blog-section .entry-meta {
  overflow: hidden;
  padding-top: 15px;
}

.blog-section .entry-meta > li {
  font-size: 14px;
  float: left;
  color:#fff;
}

@media (max-width: 767px) {
  .blog-section .entry-meta > li {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

.blog-section .entry-meta > li + li {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
  color: #ddd;
}

.blog-section .entry-meta > li + li:before {
  content: "|";
  position: absolute;
  left: 0;
  top: 0px;
  color: #ddd;
}
.blog-section .entry-meta ul{
  list-style: none;
}
.blog-section .entry-meta li{
  list-style: none;
}
.blog-section .entry-meta li a {
  color: #ddd;
}
.blog-section .entry-meta img{
  border-radius: 50%;
  margin-right: 10px;
}
.entry-media img{
  width: 100%;
}
/*===================================================
1.1 contact-area start
=======================================================*/
.tp-contact-pg-section {
  padding-bottom: 0px;
  background: #F7E3DB;
}
.tp-contact-pg-section .tp-contact-title {
  max-width: 440px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .tp-contact-pg-section .tp-contact-title {
    margin-bottom: 30px;
  }
}
.tp-contact-pg-section .tp-contact-title h2 {
  font-size: 35px;
  font-size: 2.3333333333rem;
  font-weight: 600;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .tp-contact-pg-section .tp-contact-title h2 {
    font-size: 25px;
    font-size: 1.6666666667rem;
  }
}
.tp-contact-pg-section form input,
.tp-contact-pg-section form select,
.tp-contact-pg-section form textarea {
  background: #2a2a2a;
  width: 100%;
  height: 45px;
  border: 1px solid transparent;
  border-radius: 30px;
  box-shadow: none !important;
  padding-left: 25px;
  color: #fff;
}
@media (max-width: 991px) {
  .tp-contact-pg-section form input,
  .tp-contact-pg-section form select,
  .tp-contact-pg-section form textarea {
    height: 45px;
  }
}
.tp-contact-pg-section form input:focus,
.tp-contact-pg-section form select:focus,
.tp-contact-pg-section form textarea:focus {
  border-color: #2a2a2a;
  background: #2a2a2a;
  outline: none;
}
.tp-contact-pg-section form textarea {
  height: 180px;
  padding-top: 15px;
}
.tp-contact-pg-section form {
  margin: 0 -15px;
  overflow: hidden;
}
.tp-contact-pg-section form .form-field{
  margin-bottom: 20px;
}
.tp-contact-pg-section form .form-field p{
  color: red;
  font-size: 14px;
  padding-left: 10px;
}
.tp-contact-pg-section form ::-webkit-input-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #fff;
}
.tp-contact-pg-section form :-moz-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #fff;
}
.tp-contact-pg-section form ::-moz-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #fff;
}
.tp-contact-pg-section form :-ms-input-placeholder {
  font-style: 15px;
  font-style: normal;
  color: #fff;
}
.tp-contact-pg-section form select {
  display: inline-block;
  color: #a9a9a9;
  cursor: pointer;
  opacity: 1;
  padding: 6px 25px;
  font-size: 15px;
  font-size: 1rem;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}
.tp-contact-pg-section form .submit-area {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 0;
}
@media (max-width: 767px) {
  .tp-contact-pg-section form .submit-area {
    margin-bottom: 0;
  }
}

.tp-contact-pg-section form .fullwidth {
  width: calc(100% - 25px);
  float: none;
  clear: both;
}
.tp-contact-pg-section .office-info {
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  .tp-contact-pg-section .office-info {
    padding-bottom: 60px;
  }
}
@media (max-width: 1200px) {
  .tp-contact-pg-section .office-info .col .office-info-item {
    margin-bottom: 30px;
  }
}
@media (max-width: 1200px) {
  .tp-contact-pg-section .office-info .col:last-child .office-info-item {
    margin-bottom: 0px;
  }
}
.tp-contact-pg-section .office-info .office-info-item {
  text-align: center;
  background: #FDB7B9;
  padding: 40px;
}
.tp-contact-pg-section .office-info .office-info-item .office-info-icon {
  width: 85px;
  height: 85px;
  background: #f5f5f5;
  line-height: 85px;
  text-align: center;
  margin: 0 auto;
  border-radius: 50%;
}

.tp-contact-pg-section .office-info .office-info-item .office-info-icon .fi:before{
  font-size: 40px;
}

.tp-contact-pg-section .office-info .office-info-item .office-info-text h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 20px 0;
  color: #fff;
}
.tp-contact-pg-section .office-info .office-info-item .office-info-text p {
  margin-bottom: 0;
}
.tp-contact-pg-section .tp-contact-form-area {
  padding: 50px;
  background: #202020;
  margin-bottom: -125px;
  position: relative;
  z-index: 99;
}
.tp-contact-pg-section .tp-contact-form-area .form-submit{
  text-align: center;
}
.tp-contact-pg-section .tp-contact-form-area .form-submit .template-btn{
  background-color: #2a2a2a;
}
.tp-contact-pg-section .tp-contact-form-area .form-submit .template-btn:hover{
  color: #fff;
}
@media (max-width: 1400px) {
  .tp-contact-pg-section .office-info .office-info-item {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .tp-contact-pg-section .tp-contact-form-area {
    padding: 30px;
    padding-top: 50px;
  }
}

.tp-contact-map-section .tp-contact-map {
  height: 550px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.tp-contact-map-section iframe {
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
}
.tp-contact-map-section h2.hidden {
  display: none;
}


/*==========================
1.2 footer-area start
===========================*/
.footer-area {
  padding: 40px 0 20px;
  border-top:1px solid rgba(102,102,102,.3);
}
.footer-menu ul{
  justify-content:center;
  margin-bottom: 15px;
}
.footer-menu ul li {
  width: 40px;
  height: 40px;
  line-height: 46px;
  margin: 0 5px;
  border-radius: 50%;
  background: #333;
  transition: all .3s;
  display: inline-block;
}
.footer-menu ul li:hover{
  background:#333;
  color:#fff;

}
.footer-image {
  margin-bottom: 20px;
}
.footer-image img {
  max-width: 60px;
}
.footer-menu ul li:hover a{
  color:#fff;
}
.footer-menu ul li i{
  font-style: 20px;
}
.footer-menu ul li a{
  font-size: 20px;
  color:#999;
}
.footer-sub p, .footer-sub span{
  font-size:14px;
  color: #f153ed;
}
.contact-area  .section-title h2{
  margin-bottom: 100px;
}


/* during time of react */
.hero-slider {
  overflow: hidden;
}


.portflio .Item {
  transform: matrix(0, 0, 0, 0, 0, 0);
  transition: .4s;
  width: 0;
  display: inline-block;
  opacity: 0;
  height: 0;
  position: relative;
}
.portflio .Item img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.portflio .Item.Photo, .portflio .Item.Video, .portflio .Item.Open {
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: auto;
  width: 25%;
  opacity: 1;
  height: auto;
}
.portflio .Item.Photo img, .portflio .Item.Video img, .portflio .Item.Open img {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}


/* masonary for portfolio section */
.payra-masonary {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -15px;
  margin-left: -15px;
}
.payra-masonary .grid {
  transition: .4s;
  overflow: hidden;
  transform: scale(0);
  flex: 0;
  max-width: 0;
  position: relative;
  width: 0;
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 30px;
}
.payra-masonary .grid .view-icon {
  display: inline-block;
  font-size: 30px;
  color: #000;
}

.payra-masonary .grid.active, .payra-masonary .grid.open {
  transform: scale(1.0);
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.p-lightbox-content {
  width: 300px!important;
  height: 500px!important;
}

.navigation-holder .navbar-nav {
  display: block;
}
.site-header #navbar {
  /* position: relative; */
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.fixed-navbar {
  position: relative;
  z-index: 5555;
  animation-name: fadeInDown;
  background: #F7E3DB;
}
.fixed-navbar.active .site-header.header-style-1{
  position: fixed;
  z-index: 555;
  top: -160px;
  left: 0;
  width: 100%;
  background: #F7E3DB;
  border: none;
}

@media(max-width:1200px){
  .fixed-navbar.active .site-header.header-style-1{
    top: 0px;
    background: #F7E3DB;
  }
  .navbar-brand{
    margin-bottom: 0;
  }
  .navbar-brand img{
    max-width: 50px!important;
  }
}

@media(max-width:991px){
  .navbar-brand img{
    max-width: 35px!important;
    margin-top: 10px;
    margin-bottom: 0;
  }
}


/* smothscroll  */


ul.smothscroll {
  position: fixed;
  bottom: 20px;
  right:30px;
  list-style: none;
  z-index: 99;
}
ul.smothscroll a {
  background-color: rgba(102, 102, 102, 0.9);
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 2px solid #666;
  border-radius: 45px;
  text-align: center;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
ul.smothscroll a:hover {
  background-color: #333;
}
ul.smothscroll a .fi:before{
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
}

@media(max-width:767px){
  ul.smothscroll a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}

.title {
  font-size: 3.2rem;
}

